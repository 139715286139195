import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppHeaderText from "../CustomComponents/AppHeaderText";
import AppNavbar from "../CustomComponents/AppNavbarWave";

import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";
import AppAccordian from "../CustomComponents/AppAccordian";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import { FaExclamation } from "react-icons/fa";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
import AppSelectPaymentMethod from "../CustomComponents/AppSelectPaymentMethod";
import AppSubHeaderText from "../CustomComponents/AppSubHeader";
import AppButton from "../CustomComponents/AppButton";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import { RxPlusCircled } from "react-icons/rx";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      contactData: [],
      showLoadErrorModal: false,
      upcomingServices: [],
      upcomingRepairs: [],
      selectedRepairs: [],
      selectedServices: [],
      selectedServicesTotalAmount: 0,
      buttonText: "",
      selectedCardName: "",
      selectedCardNumber: "",
      selectedCardId: "",
      selectedCardLinvio: "",
      showConfirmPaymentModal: false,
      showSelectPaymentModal: false,
      paymentMethods: [],
      linvioPaymentMethods: [],
      newPaymentMethods: [],
      selectedDescription: "",
      showOldestServiceRequiredMessage: false,
      loadingSpinner: true,
      paymentSubmittedLoadingSpinner: false,
      contactSyncId: "",
      paymentProcessor: "",
    };
  }

  componentDidMount = async () => {
    let upcomingServicesData = [];
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        // GET ACCOUNT INFO
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let accountData = getAccount.data.records;
        // console.log(
        //   "accountdata",
        //   accountData[0]?.Processor_Connection_Linvio__c
        // );
        if (accountData[0]?.Processor_Connection_Linvio__c) {
          this.setState({
            userData: accountData[0],
            total: accountData[0].Total_Balance_Due__c,
            paymentProcessor: accountData[0]?.Processor_Connection_Linvio__c,
          });
        } else {
          // console.log("hit 4");
          this.setState({ showSyncErrorModal: true });
        }
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log(getContact);
        this.setState({ contactData: getContact?.data?.records?.[0] });
        if (getContact?.data?.records?.[0]?.pymt__Synchronization_Id__c) {
          this.setState({
            contactSyncId:
              getContact?.data?.records?.[0]?.pymt__Synchronization_Id__c,
          });
        }

        // GET PAYMENT INFO
        let getPayment = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/options`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let paymentData = getPayment.data.records;
        let filteredPaymentData = [];
        if (paymentData.length) {
          filteredPaymentData = paymentData.filter(
            (item) => item.pymt__Status__c === "succeeded"
          );
          paymentData = filteredPaymentData;
        }
        const items = [
          {
            id: getContact?.data?.records?.[0]?.pymt__Synchronization_Id__c,
            paymentProcessor: accountData[0]?.Processor_Connection_Linvio__c,
          },
        ];
        if (!getContact?.data?.records?.[0]?.pymt__Synchronization_Id__c) {
          // console.log("show hit sync error modal");
          // console.log("Hit 5");
          this.setState({ showSyncErrorModal: true });
        }

        let response = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/get-payment-methods`,
          {
            params: {
              body: JSON.stringify({ items }),
              baseDomain: window.location.href,
            },
            headers: { "Content-Type": "application/json" },
          }
        );
        // console.log("pay methods response", response?.data?.methods?.results);
        let linvioMethods = [];
        if (response?.data?.methods?.results?.length) {
          linvioMethods = response?.data?.methods?.results;
          this.setState({
            linvioPaymentMethods: response?.data?.methods?.results,
          });
        } else if (!response) {
          // console.log("Hit 1");
          this.setState({ showSyncErrorModal: true });
        }

        if (filteredPaymentData) {
          this.setState({ paymentMethods: filteredPaymentData });
          for (let i = 0; i < filteredPaymentData?.length; i++) {
            // if (filteredPaymentData[i]?.pymt__Status__c === "succeeded") {
            if (filteredPaymentData[i]?.pymt__Default__c === true) {
              // console.log(filteredPaymentData[i]);
              // console.log(linvioMethods?.length);
              if (linvioMethods?.length) {
                let syncIdFound = false;
                for (let l = 0; l < linvioMethods.length; l++) {
                  if (
                    filteredPaymentData[i]?.pymt__Synchronization_Id__c ===
                    linvioMethods[l]?.synchronization_id
                  ) {
                    syncIdFound = true;
                    if (
                      filteredPaymentData[i]?.pymt__Payment_Type__c === "ACH"
                    ) {
                      this.setState({
                        selectedCardId: filteredPaymentData[i]?.Id,
                        selectedCardName:
                          filteredPaymentData[i]?.pymt__Bank_Account_Name__c ||
                          filteredPaymentData[i]?.pymt__Bank_Name__c,
                        selectedCardNumber:
                          filteredPaymentData[i]?.pymt__Last_4_Digits__c,
                        selectedCardLinvio: linvioMethods[l],
                      });
                    } else {
                      this.setState({
                        selectedCardId: filteredPaymentData[i]?.Id,
                        selectedCardName:
                          filteredPaymentData[i]?.pymt__Card_Type__c,
                        selectedCardNumber:
                          filteredPaymentData[i]?.pymt__Last_4_Digits__c,
                        selectedCardLinvio: linvioMethods[l],
                      });
                    }
                  }
                }
                if (!syncIdFound) {
                  // console.log("hit show error modal");
                  // console.log("Hit 2");
                  this.setState({ showSyncErrorModal: true });
                }
              }
            }
            // }
          }
          this.setState({
            buttonText: `PAY $${this.state.selectedServicesTotalAmount.toString()} NOW`,
          });
        }

        // GET INVOICE INFO
        let getInvoices = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/invoices`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );

        let invoices = getInvoices.data.records;
        // console.log("invoices", invoices);
        if (invoices?.length) {
          for (let i = 0; i < invoices.length; i++) {
            // CHECK FOR PENDING PAYMENTS MADE ON INVOICE
            if (invoices[i]?.Invoice_Payments__r?.records?.length) {
              let paymentRecords = invoices[i]?.Invoice_Payments__r?.records;
              let currentPaymentExists = false;
              // IF PAYMENT RECORDS EXIST, CHECK RECORD
              for (let j = 0; j < paymentRecords.length; j++) {
                // IF CURRENTLY PROCESSING TRANSACTION
                if (
                  paymentRecords[j]?.pymt__Status__c === "Open" ||
                  paymentRecords[j]?.pymt__Status__c === "Processing" ||
                  paymentRecords[j]?.pymt__Status__c === "Pending"
                ) {
                  // CHECK IF THE PROCESSING TRANACTION IS CURRENT
                  const timestamp = new Date(
                    paymentRecords[j]?.CreatedDate
                  ).getTime();
                  if (!isNaN(timestamp)) {
                    const timestampDatetime = new Date(timestamp);
                    const currentDatetime = new Date();
                    const timeDifference = currentDatetime - timestampDatetime;
                    let isWithin24 = timeDifference <= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
                    if (isWithin24) {
                      currentPaymentExists = true;
                    }
                  }
                }
              }
              // IF NO CURRENT AND PENDING TRANSACTIONS, ADD AND DISPLAY INVOICE
              if (!currentPaymentExists) {
                let obj = {
                  id: invoices[i]?.Id,
                  type: invoices[i]?.Invoice_Type__c,
                  name: invoices[i]?.Subject__c,
                  price: invoices[i]?.Total_Amount__c,
                  timestamp: invoices[i]?.Due_Date__c,
                  items: invoices[i]?.Invoice_Line_Items__r?.records,
                  balanceDue: invoices[i]?.Balance_Due__c,
                  creditMemo: invoices[i]?.Credit_Memo_Allocations__c,
                  discountAmt: invoices[i]?.Discount_Amount__c,
                  Invoice_Memo__c: invoices[i]?.Invoice_Memo__c,
                  balancePaid: invoices[i]?.Balance_Paid__c,
                };
                upcomingServicesData.push(obj);
              }
              // IF NO TRANSACTIONS ARE ON THE INVOICE, ADD AND DISPLAY INVOICE
            } else {
              let obj = {
                id: invoices[i]?.Id,
                type: invoices[i]?.Invoice_Type__c,
                name: invoices[i]?.Subject__c,
                price: invoices[i]?.Total_Amount__c,
                timestamp: invoices[i]?.Due_Date__c,
                items: invoices[i]?.Invoice_Line_Items__r?.records,
                balanceDue: invoices[i]?.Balance_Due__c,
                creditMemo: invoices[i]?.Credit_Memo_Allocations__c,
                discountAmt: invoices[i]?.Discount_Amount__c,
                Invoice_Memo__c: invoices[i]?.Invoice_Memo__c,
                balancePaid: invoices[i]?.Balance_Paid__c,
              };
              upcomingServicesData.push(obj);
            }
          }
        }

        // SORT ALL INVOICES BY DATE
        if (upcomingServicesData.length) {
          let sortedUpcomingServices = upcomingServicesData.sort(function (
            x,
            y
          ) {
            return x.timestamp - y.timestamp;
          });
          let sortedRepairs = [];
          let sortedServices = [];

          // SPLIT INVOICES INTO MAINTENANCE AND REPAIRS
          for (let i = 0; i < sortedUpcomingServices?.length; i++) {
            if (sortedUpcomingServices[i].type === "Repair") {
              // console.log(sortedUpcomingServices[i].type);
              sortedRepairs.push(sortedUpcomingServices[i]);
            } else {
              // console.log(sortedUpcomingServices[i].type);
              sortedServices.push(sortedUpcomingServices[i]);
            }
          }
          this.setState({
            upcomingServices: sortedServices,
            upcomingRepairs: sortedRepairs,
            loadingSpinner: false,
          });
        } else {
          this.setState({
            loadingSpinner: false,
          });
        }
      } catch (err) {
        console.log(err);
        this.setState({ showLoadErrorModal: true });
      }
    }
  };

  captureLinvioPayments = async (id) => {
    const items = [{ id, paymentProcessor: this.state.paymentProcessor }];
    let response = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/capture-payments`,
      {
        params: {
          body: JSON.stringify({ items }),
          baseDomain: window.location.href,
        },
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log("capture Payment Response", response);
    return await response.data;
  };
  getLinvioPayPayment = async (
    contact,
    invoiceId,
    amount,
    method,
    invoiceAmountPaid
  ) => {
    let obj = {
      contact,
      invoiceId,
      amount,
      amountPaid: invoiceAmountPaid,
      method,
      paymentProcessor: this?.state?.paymentProcessor,
      phone: this?.state?.contactData?.Phone,
    };
    // console.log(obj);
    let response = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/create-payment-for-invoice`,
      {
        params: {
          body: JSON.stringify({ obj }),
          baseDomain: window.location.href,
        },
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log("create Payment Response", response);
    if (response?.data?.success === false) {
      this.setState({ spinner: false });
      this.props.history.push({
        pathname: "/PaymentConfirmation",
        state: {
          method: this.state.selectedCardId,
          services: this.state.selectedServices,
          repairs: this.state.selectedRepairs,
          amount: this.state.selectedServicesTotalAmount,
          success: false,
        },
      });
    } else {
      return await response.data;
    }
  };
  initPayInvoice = async () => {
    let services = this.state.selectedServices;
    let repairs = this.state.selectedRepairs;
    let allInvoices = services.concat(repairs);
    // console.log("processor", this?.state?.paymentProcessor);
    // console.log(this.state.contactSyncId, this.state.selectedCardLinvio);
    for (let i = 0; i < allInvoices.length; i++) {
      let invoiceId = allInvoices[i]?.id;
      let invoiceAmount = allInvoices[i]?.price;
      let invoiceAmountPaid = allInvoices[i]?.balancePaid;
      const { payment } = await this.getLinvioPayPayment(
        this.state.contactSyncId,
        invoiceId,
        invoiceAmount,
        this.state.selectedCardLinvio,
        invoiceAmountPaid
      );
      // console.log("payment");
      // console.log("1", payment);
      // console.log("2", payment.id);
      // console.log("3", public_key);
      if (payment?.id) {
        let capture = await this.captureLinvioPayments(payment?.id);
        // console.log("capture response", capture);
        if (capture?.methods?.id?.length) {
          this.setState({ spinner: false });
          this.props.history.push({
            pathname: "/PaymentConfirmation",
            state: {
              method: this.state.selectedCardId,
              services: this.state.selectedServices,
              repairs: this.state.selectedRepairs,
              amount: this.state.selectedServicesTotalAmount,
              success: true,
            },
          });
        } else {
          this.setState({ spinner: false });
          this.props.history.push({
            pathname: "/PaymentConfirmation",
            state: {
              method: this.state.selectedCardId,
              services: this.state.selectedServices,
              repairs: this.state.selectedRepairs,
              amount: this.state.selectedServicesTotalAmount,
              success: false,
            },
          });
        }
      } else {
        this.setState({ spinner: false });
        this.props.history.push({
          pathname: "/PaymentConfirmation",
          state: {
            method: this.state.selectedCardId,
            services: this.state.selectedServices,
            repairs: this.state.selectedRepairs,
            amount: this.state.selectedServicesTotalAmount,
            success: false,
          },
        });
      }
    }
  };

  // SELECT SERVICE FROM ACCORDION
  setSelectedService = (id) => {
    let servicesArray = this?.state?.upcomingServices;
    let currentlySelectedServices = this.state.selectedServices;
    // LOOP THROUGH ALL SERVICES TO FIND SELECTED SERVICE
    for (let i = 0; i < servicesArray?.length; i++) {
      if (servicesArray[i]?.id === id) {
        let selectedService = servicesArray[i];
        // IF ALREADY SELECTED
        if (currentlySelectedServices.includes(selectedService)) {
          let index = currentlySelectedServices.indexOf(selectedService);
          currentlySelectedServices.splice(index, 1);
          this.setState({ selectedServices: currentlySelectedServices });
          // IF NOT SELECTED
        } else {
          currentlySelectedServices.push(selectedService);
          this.setState({ selectedServices: currentlySelectedServices });
        }
        // IF SERVICES ARE SELECTED BUT SELECTED SERVICES ARRAY DOES NOT CONTAIN THE OLDEST, SHOW MESSAGE
        if (
          currentlySelectedServices.length &&
          !currentlySelectedServices.includes(servicesArray[0])
        ) {
          this.setState({ showOldestServiceRequiredMessage: true });
        } else {
          this.setState({ showOldestServiceRequiredMessage: false });
        }
        this.getServicesTotal();
      }
    }
  };

  setSelectedRepair = (id) => {
    let repairsArray = this.state.upcomingRepairs;
    let selected = this.state.selectedRepairs;
    for (let i = 0; i < repairsArray?.length; i++) {
      if (repairsArray[i]?.id === id) {
        let selectedRepair = repairsArray[i];
        // IF ALREADY INCLUDED
        if (selected.includes(selectedRepair)) {
          let index = selected.indexOf(selectedRepair);
          selected.splice(index, 1);
          this.setState({ selectedRepairs: selected });
        } else {
          selected.push(selectedRepair);
          this.setState({ selectedRepairs: selected });
        }
      }
    }
    this.setState({ selectedRepairs: selected });
    this.getServicesTotal();
  };

  setSelectedDescription = (item) => {
    if (this.state.selectedDescription === item) {
      this.setState({ selectedDescription: "" });
    } else {
      this.setState({ selectedDescription: item });
    }
  };

  getServicesTotal = () => {
    let total = 0;
    for (let l = 0; l < this.state.selectedServices.length; l++) {
      // console.log("selected", this.state.selectedServices);
      total =
        total +
        this.state.selectedServices[l].price -
        this.state.selectedServices[l].balancePaid;
    }
    for (let l = 0; l < this.state.selectedRepairs.length; l++) {
      total =
        total +
        this.state.selectedRepairs[l].price -
        this.state.selectedRepairs[l].balancePaid;
    }
    this.setState({
      selectedServicesTotalAmount: total.toFixed(2),
      buttonText: `PAY $${total.toFixed(2)} NOW`,
    });
  };

  // SELECT PAYMENT METHOD BOX CHECK LOGIC
  onRadioValueChange = (event) => {
    let syncIdFound = false;
    for (let i = 0; i < this?.state?.paymentMethods?.length; i++) {
      if (this.state?.paymentMethods[i]?.Id === event) {
        let selectedItem = this.state.paymentMethods[i];
        // console.log(selectedItem);
        for (let l = 0; l < this.state.linvioPaymentMethods.length; l++) {
          if (
            this.state.linvioPaymentMethods[l]?.contact_synchronization_id ===
              this.state.contactSyncId &&
            selectedItem?.pymt__Synchronization_Id__c ===
              this.state.linvioPaymentMethods[l]?.synchronization_id
          ) {
            syncIdFound = true;
            if (selectedItem.pymt__Payment_Type__c === "ACH") {
              this.setState({
                selectedCardId: selectedItem?.Id,
                selectedCardName:
                  selectedItem?.pymt__Bank_Account_Name__c ||
                  selectedItem?.pymt__Bank_Name__c,
                selectedCardNumber: selectedItem?.pymt__Last_4_Digits__c,
                selectedCardLinvio: this.state.linvioPaymentMethods[l],
                showSelectPaymentModal: false,
              });
            } else {
              this.setState({
                selectedCardId: selectedItem?.Id,
                selectedCardName: selectedItem?.pymt__Card_Type__c,
                selectedCardNumber: selectedItem?.pymt__Last_4_Digits__c,
                selectedCardLinvio: this.state.linvioPaymentMethods[l],
                showSelectPaymentModal: false,
              });
            }
          }
        }
        if (!syncIdFound) {
          // console.log("Hit 3");
          this.setState({ showSyncErrorModal: true });
        }
      }
    }
  };

  createTransaction = async () => {
    this.setState({ spinner: true });
    let account = localStorage.getItem("accountId");
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = mm + "/" + dd + "/" + yyyy;
    let jsonObj = {
      method: this.state.selectedCardId,
      relatedAccount: account,
      totalAmount: this.state.selectedServicesTotalAmount,
      selectedServices: this.state.selectedServices,
      selectedRepairs: this.state.selectedRepairs,
      date: formattedToday,
    };
    let finalJsonObj = JSON.stringify(jsonObj);
    let createTransaction = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/createTransaction`,
      {
        params: {
          json: finalJsonObj,
          token: localStorage.getItem("token"),
          baseDomain: window.location.href,
        },
      }
    );

    // CHANGE THIS IF TO ACTUAL API SUCCESS
    if (createTransaction.data.success === true) {
      this.setState({ spinner: false });
      this.props.history.push({
        pathname: "/PaymentConfirmation",
        state: {
          method: this.state.selectedCardId,
          services: this.state.selectedServices,
          amount: this.state.selectedServicesTotalAmount,
          success: true,
        },
      });
    } else {
      this.setState({ spinner: false });
      this.props.history.push({
        pathname: "/PaymentConfirmation",
        state: {
          method: this.state.selectedCardId,
          services: this.state.selectedServices,
          repairs: this.state.selectedRepairs,
          amount: this.state.selectedServicesTotalAmount,
          success: false,
        },
      });
    }
  };

  render() {
    return (
      <div>
        <AppNavbar />

        <div className="paymentsScreenContainer">
          <AppHeaderText text="Pay my bill" />
          <p
            className="app-paragraph-text"
            style={{ marginTop: "25px", maxWidth: "1250px" }}
          >
            Select all invoices you would like to pay now by checking or
            unchecking below. If you have more than one outstanding service
            charge, you <span style={{ fontWeight: "700" }}>must</span> pay the
            oldest charge before the most recent one. Please call{" "}
            <span style={{ fontWeight: "700" }}>1-800-990-7440</span> for
            assistance with partial payment
            {/* Starting July 25th, we will be working to improve our payment
            processes. While this integration is underway, making payments and
            adding a new payment method will be temporarily suspended.
            <br></br>
            <br></br>
            We apologize for any inconvenience. Systems should be back online as
            of July 29th. If you have an emergency please call our customer
            service team at{" "} */}
            <span style={{ fontWeight: "700" }}>855-554-7665</span>.
          </p>
          {this.state.loadingSpinner ? (
            <div className="paymentsLoadingSpinnerContainer">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div style={{ maxWidth: "850px", marginTop: "65px" }}>
              {this.state.upcomingServices?.length ||
              this.state.upcomingRepairs?.length ? (
                <AppAccordian
                  upcomingServices={this.state.upcomingServices}
                  upcomingRepairs={this.state.upcomingRepairs}
                  selectedServices={this.state.selectedServices}
                  selectedRepairs={this.state.selectedRepairs}
                  selectedDescription={this.state.selectedDescription}
                  total={this.state.selectedServicesTotalAmount}
                  setSelectedService={(id) => this.setSelectedService(id)}
                  setSelectedRepair={(id) => this.setSelectedRepair(id)}
                  setSelectedDescription={(id) =>
                    this.setSelectedDescription(id)
                  }
                />
              ) : (
                <AppSubHeaderText text="NO CURRENT INVOICES" />
              )}

              {!this.state.upcomingServices.length &&
              !this.state.upcomingRepairs.length ? (
                <div style={{ textAlign: "right", marginTop: "20px" }}>
                  <AppSubHeaderText text="NO PAYMENT REQUIRED" />
                </div>
              ) : (
                <>
                  <div
                    className="selectPaymentContainer"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginBottom: "25px",
                    }}
                  >
                    <AppSelectPaymentMethod
                      onClick={() =>
                        this.setState({ showSelectPaymentModal: true })
                      }
                      selectedCard={
                        this?.state?.selectedCardName +
                        " - " +
                        this?.state?.selectedCardNumber
                      }
                    />
                  </div>
                  {this.state.showOldestServiceRequiredMessage && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "50px",
                        }}
                      >
                        <FaExclamation
                          size={32}
                          color="white"
                          style={{
                            backgroundColor: "#D54100",
                            border: "none",
                            borderRadius: "25px",
                            padding: "5px",
                            marginRight: "15px",
                          }}
                        />
                        <div>
                          <p
                            style={{
                              fontFamily: "museo-sans",
                              color: "#D54100",
                              fontSize: "14px",
                              fontWeight: "700",
                              margin: "0px",
                            }}
                          >
                            If you wish to pay a service invoice, you must start
                            by paying the oldest invoice first.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // maxWidth: "770px",
                    }}
                  >
                    {this.state.selectedServicesTotalAmount > 0 &&
                      this?.state?.selectedCardId &&
                      !this.state.showOldestServiceRequiredMessage && (
                        // this?.state?.selectedCardName && (
                        <AppButton
                          tabindex={20}
                          text={this.state.buttonText}
                          onClick={() =>
                            this.setState({ showConfirmPaymentModal: true })
                          }
                          arialabel={`Pay ${this.state?.selectedServicesTotalAmount} now`}
                        />
                      )}
                  </div>
                </>
              )}
            </div>
          )}
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: 100 }}
          >
            <PrivacyPolicy
              color="black"
              tabindex={21}
              style={{ marginLeft: "40px" }}
            />
            <p
              style={{
                color: "black",
                fontWeight: "500",
                marginRight: "10px",
                fontSize: "13px",
              }}
            >
              |
            </p>
            <TermsAndPrivacy color="black" tabindex={22} />
          </div>
        </div>
        <div
          className="termsAndPrivacyContainerPayments"
          style={{ marginTop: 100 }}
        >
          <TermsAndPrivacy color="#727272" tabindex={21} />
        </div>

        {/* SELECT PAYMENT MODAL */}
        <Modal
          contentClassName="custom-modal"
          size="lg"
          aria-labelledby="Select Payment Modal"
          centered
          show={this.state.showSelectPaymentModal}
          style={{ padding: "0px 20px" }}
        >
          <Modal.Body>
            <div style={{ padding: "30px 20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  marginBottom: "10px",
                }}
              >
                <AppSubHeaderText text="SELECT/ADD PAYMENT METHOD" style={{}} />
                <button
                  className="closePaymentModalButton"
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() =>
                    this.setState({ showSelectPaymentModal: false })
                  }
                  aria-controls="Close select payment type modal"
                  aria-label="Close select payment type modal"
                >
                  <IoIosCloseCircleOutline
                    size={50}
                    style={{ marginBottom: "0px" }}
                    color="#d44203"
                  />
                </button>
              </div>
              <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                {this.state.paymentMethods.map((item) => {
                  // console.log(item);
                  // console.log(this.state.selectedCardNumber);
                  return (
                    <div key={item.Id} style={{ marginBottom: "20px" }}>
                      <button
                        className="radio"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => this.onRadioValueChange(item.Id)}
                        aria-label={`Select card ending in ${item?.pymt__Last_4_Digits__c}`}
                      >
                        {this.state.selectedCardId === item.Id ? (
                          <CgRadioChecked
                            size={35}
                            color="#727272"
                            style={{ marginRight: "10px" }}
                          />
                        ) : (
                          <CgRadioCheck
                            size={35}
                            color="#727272"
                            style={{ marginRight: "10px" }}
                          />
                        )}
                        <p
                          className="radio"
                          style={{
                            fontFamily: "museo-sans",
                            fontSize: "20px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            textAlign: "left",
                          }}
                        >
                          {item?.pymt__Payment_Type__c === "ACH"
                            ? item?.pymt__Bank_Account_Name__c ||
                              item?.pymt__Bank_Name__c
                            : item?.pymt__Card_Type__c}{" "}
                          - {item?.pymt__Last_4_Digits__c}
                        </p>
                      </button>
                    </div>
                  );
                })}
                <Link
                  to={"/AddNewPaymentMethod"}
                  className="addPaymentDiv"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#D54100",
                    // marginTop: "10px",
                    background: "transparent",
                    border: "none",
                    textDecoration: "none",
                  }}
                  aria-label="Navigate to add a new payment method"
                >
                  <RxPlusCircled
                    size={35}
                    style={{ marginRight: "10px", borderRadius: 18 }}
                    className="addPaymentIcon"
                    color="#D54100"
                  />

                  <p
                    style={{
                      fontFamily: "museo-sans",
                      fontWeight: "700",
                      display: "contents",
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                  >
                    Add new method
                  </p>
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* CONFIRM PAYMENT MODAL */}
        <Modal
          contentClassName="custom-modal"
          size="lg"
          aria-labelledby="Confirm Payment Modal"
          centered
          show={this.state.showConfirmPaymentModal}
          style={{ padding: "0px 25px" }}
        >
          <Modal.Body>
            {!this.state.paymentSubmittedLoadingSpinner ? (
              <>
                <button
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "15px",
                    marginTop: "25px",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() =>
                    this.setState({ showConfirmPaymentModal: false })
                  }
                  aria-label="Close confirm payment modal"
                >
                  <div className="closePaymentModalButton">
                    <IoIosCloseCircleOutline
                      size={50}
                      style={{ marginBottom: "0px" }}
                      color="#d44203"
                    />
                  </div>
                </button>
                <p
                  style={{
                    color: "#1A4EA2",
                    fontFamily: "Oswald",
                    fontSize: "25px",
                    fontWeight: "100",
                    textAlign: "center",
                    marginBottom: 20,
                  }}
                >
                  Charge {this?.state?.selectedCardName} account ending in{" "}
                  {this?.state?.selectedCardNumber} a total of $
                  {this?.state?.selectedServicesTotalAmount}?
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <AppButton
                    text="CONFIRM"
                    // onClick={() => this.createTransaction()}
                    onClick={() => {
                      this.setState({ paymentSubmittedLoadingSpinner: true });
                      this.initPayInvoice();
                    }}
                    arialabel="Confirm and submit payment"
                  />
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                  flexDirection: "column",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p
                  style={{
                    fontFamily: "museo-sans",
                    marginTop: 20,
                    textAlign: "center",
                    marginLeft: "5%",
                    marginRight: "5%",
                    maxWidth: "90%",
                  }}
                >
                  Currently processing, this can take up to a minute—please do
                  not close this window or exit your browser until you get a
                  confirmation.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
        {this.state.showLoadErrorModal && <LoadErrorModal />}
        <Modal
          show={this.state.showSyncErrorModal}
          onHide={() => this.setState({ showSyncErrorModal: false })}
          aria-labelledby="Add payment gateway info error modal"
          centered
          contentClassName="custom-modal-dashboard"
          style={{ borderRadius: "25px" }}
        >
          <Modal.Body>
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontFamily: "Oswald",
                    color: "#d44203",
                    fontWeight: "300",
                    lineHeight: "30px",
                    marginBottom: "50px",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* {this.state.modalMessage} */}
                  Error syncing payment method ID, please contact customer
                  service
                </p>
                <p
                  style={{
                    fontSize: "22px",
                    fontFamily: "Oswald",
                    color: "#d44203",
                    fontWeight: "300",
                    lineHeight: "30px",
                    marginBottom: "50px",
                    textAlign: "center",
                  }}
                >
                  {this.state.gatewayErrorMessage}
                </p>
                <div>
                  <Link
                    to={"/Contact"}
                    style={{
                      color: "#D54100",
                      textDecoration: "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    tabIndex={38}
                    aria-label="Navigate to contact screen"
                  >
                    <div
                      className="appButton"
                      style={{
                        marginTop: "10px",
                        color: "#1A4EA2",
                        backgroundColor: "#FFE160",
                        border: "none",
                        borderRadius: 25,
                        width: "fit-content",
                        fontSize: "20px",
                        fontWeight: "700",
                        fontFamily: "museo-sans",
                        letterSpacing: "2.5px",
                        marginBottom: "20px",
                        paddingRight: "40px",
                        paddingLeft: "40px",
                        textDecoration: "none",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        Contact Customer Service
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(Dashboard);
